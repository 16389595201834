import { runInAction } from "mobx";
import { DashboardViewStore } from "../features/dashboard/stores/dashboard-view-store";
import { TransportViewStore } from "../features/transport/stores/view/transport-view-store";
import { MapViewStore } from "features/map/stores/map-view-store";
import { ChartsDataProvider } from "features/charts/stores/charts-data-provider";

export class ViewStore {
  readonly dashboard = new DashboardViewStore();
  readonly charts = new ChartsDataProvider();
  readonly transport = new TransportViewStore();
  readonly map = new MapViewStore();
}

export class RootStore {
  readonly view = new ViewStore();
}

export let rootStore = new RootStore();

export function initStores() {
  rootStore = runInAction(() => new RootStore());
}

export default rootStore;
