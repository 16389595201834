import { IServerTransportsModel } from "models/server-models";
import { IClientTransportsModel } from "models/client-models";
import { ITransportFilterParams } from "../../utils/transport-utils";
import { TransportDataTableProvider } from "../data/transport-data-table-provider";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import { serverToClientTransportsModel } from "models/mappers";
import { StructureDataProvider } from "common/providers/structure-data-provider";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class TransportViewStore extends BaseTableViewStore<
  IServerTransportsModel,
  IClientTransportsModel,
  ITransportFilterParams,
  TransportDataTableProvider
> {
  constructor() {
    super(new TransportDataTableProvider());
  }

  private readonly structureProvider = new StructureDataProvider();
  private readonly appContext = new SharedContextStore();

  mapServerToClientModel = (item): IClientTransportsModel =>
    serverToClientTransportsModel(item);

  getWorkplace = (workplaceId: number) =>
    this.structureProvider.getWorkplace(workplaceId);
  getPosition = (positionId: string) =>
    this.structureProvider.getPosition(positionId);

  private resolveWorkplaceWithPosition = (
    workplaceId: number,
    positionId: string
  ) => {
    const workplace = this.getWorkplace(workplaceId)?.name;
    const position = this.getPosition(positionId)?.name;
    if (workplace && !position) return workplace;
    else if (!workplace && position) return position;
    else if (!workplace && !position) return "--";
    return `${workplace} - ${position} `;
  };

  private resolveWorkplaceWithLanes = (
    workplaceId: number,
    laneIds: string[]
  ) => {
    const workplace = this.getWorkplace(workplaceId)?.name;
    const lanes = laneIds?.map((id) => this.getPosition(id)?.name);
    if (workplace && !lanes) return [workplace];
    else if (!workplace && lanes) return ["bulk-zone:", ...lanes];
    else if (!workplace && !lanes) return ["--"];
    return [`${workplace} - bulk-zone:`, ...lanes];
  };

  getWorkplacePositionNames = (workplaceId: number, positionId: string) =>
    this.resolveWorkplaceWithPosition(workplaceId, positionId);
  getWorkplaceLanesNames = (workplaceId: number, laneIds: string[]) =>
    this.resolveWorkplaceWithLanes(workplaceId, laneIds);

  deleteTransport = (id: number) => this.removeItem(id);
  patchItemPriority = (id: number) =>
    this.patchItemWithUrl(`${id}/priority`, this.getLocalPatch(id));
  patchItemQueue = (id: number) =>
    this.patchItemWithUrl(`${id}/queue`, this.getLocalPatch(id));

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
}
