export const basePath = "/amr-transport";
export enum RoutePaths {
  home = "",
  charts = "/charts",
  transport = "/transport",
  warehouseMap = "/map",
}

export function menu() {
  return {
    items: [
      {
        title: "Dashboard",
        link: basePath,
        icon: "Dashboard",
      },
      {
        title: "charts",
        link: basePath + RoutePaths.charts,
        icon: "QueryStats",
      },
      {
        title: "Transport",
        link: basePath + RoutePaths.transport,
        icon: "LocalShipping",
      },
      {
        title: "map",
        link: basePath + RoutePaths.warehouseMap,
        icon: "Map",
      },
    ],
  };
}
