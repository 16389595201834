import { environment } from "environment";
import { flatten } from "lodash";
import { computed, makeObservable } from "mobx";
import MultiDataStoreProvider from "wes_shell_app/multi-data-store-provider";
import SharedContextStore from "wes_shell_app/shared-context-store";
import {
  IPositionServerModel,
  IStructureServerModel,
  IWorkplaceServerModel,
} from "../../models/server-models";

export class StructureDataProvider extends MultiDataStoreProvider<
  IStructureServerModel,
  number
> {
  static _instance: StructureDataProvider;

  constructor(readonly store = new SharedContextStore()) {
    super();
    makeObservable(this, {
      workplaces: computed,
    });
    if (
      store.appContext.currentStationId !==
      StructureDataProvider._instance?.currentStationId
    ) {
      StructureDataProvider._instance = null;
    }
    if (StructureDataProvider._instance) return StructureDataProvider._instance;
    StructureDataProvider._instance = this;
  }
  private get currentStationId() {
    return this.store.appContext.currentStationId;
  }

  protected get serviceApiUrl() {
    return environment.serviceApi;
  }
  protected get endpointUrl() {
    return "ui/structure";
  }

  private get _workplaces(): Map<number, IWorkplaceServerModel> {
    return new Map(
      flatten(
        this.items.map((x) =>
          x.stations
            ?.filter((x) => x.id === this.currentStationId)
            .reduce(
              (data, station) => flatten([...data, station.workplaces]),
              []
            )
        )
      ).map((x) => [x.id, x])
    );
  }

  private get _positions(): Map<string, IPositionServerModel> {
    return new Map(
      flatten(
        this.workplaces.map((x) =>
          x.position.reduce(
            (data, workplace) => flatten([...data, workplace]),
            []
          )
        )
      ).map((x) => [x.id, x])
    );
  }

  //@ts-ignore
  get workplaces() {
    return [...this._workplaces.values()];
  }

  getWorkplacePositions = (workplaceId: number) =>
    this._workplaces.get(workplaceId)?.position || [];
  getWorkplace = (workplaceId: number) => this._workplaces.get(workplaceId);
  getPosition = (positionId: string) => this._positions.get(positionId);
}
