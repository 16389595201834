import { observer } from "mobx-react";
import { SimpleCard } from "wes_shell_app/simpleCard";
import TimeChart from "wes_shell_app/time-chart";
import { useChartsStore } from "../hooks/use-charts-store";

const TransportsChart = observer(() => {
  const store = useChartsStore().transports;

  return (
    <SimpleCard>
      <TimeChart
        data={store.items}
        store={store}
        intlId="transportsChart"
        type="line"
      />
    </SimpleCard>
  );
});

export default TransportsChart;
