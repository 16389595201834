import { environment } from "environment";
import MultiDataStoreProvider from "wes_shell_app/multi-data-store-provider";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { IClientQueueModel } from "models/client-models";

export class QueueDataStore extends MultiDataStoreProvider<any, string> {
  static _instance: QueueDataStore;

  protected applyGetResult = (response: IClientQueueModel[]) => {
    response.forEach((item) => this._items.set(item.name, item));
    return this.items;
  };

  constructor(readonly store = new SharedContextStore()) {
    super();
    if (
      store.appContext.currentStationId !==
      QueueDataStore._instance?.currentStationId
    ) {
      QueueDataStore._instance = null;
    }
    if (QueueDataStore._instance) return QueueDataStore._instance;
    QueueDataStore._instance = this;
  }

  private get currentStationId() {
    return this.store.appContext.currentStationId;
  }
  protected get serviceApiUrl() {
    return environment.serviceApi;
  }
  protected get endpointUrl() {
    return `ui/queues/${this.currentStationId}`;
  }
}
