import React from "react";
import { AppProvider } from "./providers/app-provider";
import { Routes } from "./routing/routes";
import { setTranslation } from "localization/localization";

function App() {
  React.useMemo(() => {
    setTranslation();
  }, []);

  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  );
}

export default App;
