import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { parse } from "cookie";
import SharedContextStore from "wes_shell_app/shared-context-store";
import {
  IAPIImagesData,
  IAPIRobotsMapData,
  IWebSocketMapUpdateModel,
  IWebsocketMessageModel,
  WebsocketMessageType,
} from "models/server-models";
import { environment } from "environment";
import { BaseDiagramMapStore } from "wes_shell_app/base-diagram-map-store";
import { mapProvider } from "./data/map-provider";

const TOKEN = "wes-token";

interface IAuthTokens {
  accessToken: string;
}

export const getToken = (): IAuthTokens => ({
  accessToken: parse(document.cookie)?.[TOKEN],
});

export class MapViewStore extends BaseDiagramMapStore<
  IAPIRobotsMapData,
  IAPIImagesData,
  IWebSocketMapUpdateModel,
  mapProvider
> {
  private readonly sharedContext: SharedContextStore = new SharedContextStore();

  constructor() {
    super(new mapProvider());
    makeObservable(this, {
      activeComponents: observable,
      components: computed,
      setNewComponents: action,
      zoomLevel: observable,
    });
  }

  activeComponents: IWebSocketMapUpdateModel[] = [];
  zoomLevel: number = 1;

  get components() {
    return this.activeComponents;
  }

  setZoomLevel(level: number) {
    runInAction(() => {
      this.zoomLevel = level;
    });
  }

  setNewComponents(components: IWebSocketMapUpdateModel[]) {
    this.activeComponents = [];
    runInAction(() => {
      components.forEach((newComponent) => {
        const existingIndex = this.activeComponents.findIndex(
          (component) => component.id === newComponent.id
        );

        if (existingIndex !== -1) {
          this.activeComponents[existingIndex] = newComponent;
        } else {
          this.activeComponents.push(newComponent);
        }
        this.activeComponents = [...this.activeComponents];
      });
    });
  }

  //websockets
  wsConnection: WebSocket;
  timeout;

  get currentUserStore() {
    return this.sharedContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }

  private getClientIpAddress = async () => {
    try {
      const response = await fetch("https://ipapi.co/json");
      const result = await response.json();
      return result.ip;
    } catch (e) {}
  };

  openConnection = async () => {
    console.log("openConnection");
    const clientIpAddress = await this.getClientIpAddress();
    this.wsConnection = new WebSocket(
      `${environment.warehouseMapWss}${this.sharedContext.appContext.currentStationId}`
    );
    const authMessage = {
      message: "login",
      client: clientIpAddress,
      jwt: getToken().accessToken,
    };

    if (this.wsConnection.readyState === 1) {
      this.wsConnection.send(JSON.stringify(authMessage));
    }

    this.wsConnection.onopen = () => {
      console.log("this.wsConnection.onopen");
      this.wsConnection.send(JSON.stringify(authMessage));
    };

    this.wsConnection.onerror = async (event) => {
      console.log("error", event);
    };

    this.wsConnection.onmessage = async (event) => {
      console.log("this.wsConnection.onmessage");
      // first check if connection is live
      if (this.wsConnection.readyState === 1) {
        const data: IWebsocketMessageModel = JSON.parse(event.data);
        console.log(data);
        if (data.messageType === WebsocketMessageType.mapUpdate) {
          this.setNewComponents(data.message);
        }
      }
    };
  };

  closeConnection = () => {
    if (this.wsConnection) {
      this.wsConnection.close();
    }
  };
}
