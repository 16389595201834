import React from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";
import { useRouting } from "wes_shell_app/use-routing";
import { DashboardView } from "../features/dashboard/views/dashboard-view";
import { ChartsView } from "features/charts/views/charts-view";
import { TransportView } from "../features/transport/views/transport-view";
import { useNavigationStore } from "wes_shell_app/use-navigation-store";
import { RoutePaths, menu, basePath } from "./menu";
import { observer } from "mobx-react";
import MapView from "features/map/views/map-view";

export const Routes = observer(() => {
  const navStore = useNavigationStore();
  const routerStore = useRouting();

  React.useEffect(() => {
    routerStore.addPaths([...Object.values(RoutePaths)], basePath);
    navStore.setItems(menu().items);
  }, []);

  return (
    <ReactRoutes>
      <Route path={RoutePaths.home} element={<DashboardView />} />
      <Route path={RoutePaths.charts} element={<ChartsView />} />
      <Route path={RoutePaths.transport} element={<TransportView />} />
      <Route path={RoutePaths.warehouseMap} element={<MapView />} />
    </ReactRoutes>
  );
});
