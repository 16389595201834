import { environment } from "environment";
import TimeChartDataProvider from "wes_shell_app/time-chart-data-provider";

class ChartsCommonDataProvider extends TimeChartDataProvider {
  protected get serviceApiUrl() {
    return environment.serviceApi;
  }
  protected get endpointUrl() {
    return `ui/graph/${this.stationId}`;
  }
}

export class ChartsDataProvider {
  readonly transports = new ChartsCommonDataProvider([
    { name: "Total Transport Requests" },
    { name: "Total Transports Finished" },
    { name: "Actual Waiting Requests Count" },
  ]);
  readonly transportsByRobot = new ChartsCommonDataProvider([
    { name: "Transports Finished by Robot", tagName: "robotName" },
  ]);
  readonly buffers = new ChartsCommonDataProvider([
    { name: "Actual Full Buffers Count" },
  ]);
  readonly robots = new ChartsCommonDataProvider([
    { name: "Actual Charging Robots Count" },
  ]);
}
