import { environment } from "environment";
import SingleDataStoreProvider from "wes_shell_app/single-data-store-provider";
import { IServerCreateTransportRequestModel } from "models/server-models";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class TrasportRequestDataProvider extends SingleDataStoreProvider<IServerCreateTransportRequestModel> {
  get endpointUrl(): string {
    return "ui/transports";
  }
  protected serviceApiUrl = environment.serviceApi;

  get defaultModel() {
    //TODO: workaround for initialization of store
    const store = new SharedContextStore();
    return {
      stationId: store.appContext.currentStationId,
      priority: 0,
    };
  }
}
