import { observer } from "mobx-react";
import React from "react";
import KPI from "../../../common/base-components/kpi/kpi";
import KPIUnprocessed from "../../../common/base-components/kpi/kpi-unprocessed";
import { useDashboardStore } from "../hooks/use-dashboard-store";
import {
  BatteryCharging30,
  Battery2Bar,
  Warning,
  WrongLocation,
  Block,
  Dock,
  SyncAlt,
  QueryStats,
  Margin,
} from "@mui/icons-material";
import { Dialog } from "../../../common/base-components/dialog/dialog";
import { TransportsDataTable } from "./transports-data-table";
import { UtilizationDataTable } from "./utilization-data-table";
import { BuffersDataTable } from "./buffers-data-table";
import { ChargingRobotDataTable } from "./charging-robot-data-table";
import { InUseRobotDataTable } from "./in-use-robots-data-table";
import { LowBatteryRobotDataTable } from "./low-battery-robot-data-table";
import { MislocalizedRobotDataTable } from "./mislocalized-robot-data-table";
import { ErrorRobotDataTable } from "./error-robot-data-table";
import { RunStopRobotDataTable } from "./run-stop-robot-data-table";
import { SimpleCard } from "wes_shell_app/simpleCard";

export const DashboardHeader = observer(() => {
  const store = useDashboardStore();
  React.useEffect(() => {
    store.openConnection();

    return () => {
      store.closeConnection();
    };
  }, [store]);

  return (
    <React.Fragment>
      <SimpleCard topMargin={false}>
        <div className="flex flex-col space-2 w-full">
          <div className="inline-flex flex-row justify-between flex-wrap gap-x-7">
            <div className="inline-flex flex-row space-x-10">
              <Dialog
                intlTitle="transportsToday"
                trigger={
                  <KPI
                    icon={
                      <SyncAlt
                        fontSize="large"
                        color={
                          store.totalTransports > 0 ? "primary" : "inherit"
                        }
                      />
                    }
                    intlId="transportsToday"
                  >
                    {store.transportsToday}
                  </KPI>
                }
              >
                <TransportsDataTable rows={store.totalTransportTable} />
              </Dialog>
              <Dialog
                intlTitle="utilization"
                trigger={
                  <KPI
                    icon={
                      <QueryStats
                        fontSize="large"
                        color={store.utilization > 0 ? "primary" : "inherit"}
                      />
                    }
                    intlId="utilization"
                  >
                    {store.utilization} %
                  </KPI>
                }
              >
                <UtilizationDataTable rows={store.utilizationDataTable} />
              </Dialog>
              {store.totalBuffersCount > 0 ? (
                <Dialog
                  intlTitle="buffersFullness"
                  trigger={
                    <KPI
                      icon={
                        <Margin
                          fontSize="large"
                          color={
                            store.buffersFullnessPerc > 90
                              ? "error"
                              : store.buffersFullnessPerc > 60
                              ? "warning"
                              : "inherit"
                          }
                        />
                      }
                      intlId="buffersFullness"
                    >
                      {store.buffersFullnessText}
                    </KPI>
                  }
                >
                  <BuffersDataTable rows={store.buffersDataTable} />
                </Dialog>
              ) : null}
            </div>
            <div className="inline-flex flex-row space-x-10">
              <Dialog
                intlTitle="robotsInUse"
                trigger={
                  <KPI
                    icon={
                      <Dock
                        fontSize="large"
                        color={
                          store.operatingRobots > 0 ? "primary" : "inherit"
                        }
                      />
                    }
                    intlId="robotsInUse"
                  >
                    {store.operatingRobots}
                  </KPI>
                }
              >
                <InUseRobotDataTable rows={store.totalRobotsDataTable} />
              </Dialog>

              <Dialog
                intlTitle="lowBattery"
                trigger={
                  <KPI
                    icon={
                      <Battery2Bar
                        fontSize="large"
                        color={store.lowBattery > 0 ? "error" : "inherit"}
                      />
                    }
                    intlId="lowBattery"
                  >
                    {store.lowBattery}
                  </KPI>
                }
              >
                <LowBatteryRobotDataTable
                  rows={store.lowBatteryRobotsDataTable}
                />
              </Dialog>

              <Dialog
                intlTitle="charging"
                trigger={
                  <KPI
                    icon={
                      <BatteryCharging30
                        fontSize="large"
                        color={store.charging > 0 ? "success" : "inherit"}
                      />
                    }
                    intlId="charging"
                  >
                    {store.charging}
                  </KPI>
                }
              >
                <ChargingRobotDataTable rows={store.chargingRobotsDataTable} />
              </Dialog>

              <Dialog
                intlTitle="mislocalized"
                trigger={
                  <KPI
                    icon={
                      <div className="flex flex-row items-center">
                        <WrongLocation
                          fontSize="large"
                          color={store.mislocalized > 0 ? "error" : "inherit"}
                        />
                      </div>
                    }
                    intlId="mislocalized"
                  >
                    {store.mislocalized}
                  </KPI>
                }
              >
                <MislocalizedRobotDataTable
                  rows={store.mislocalizedRobotsDataTable}
                />
              </Dialog>

              <Dialog
                intlTitle="error"
                trigger={
                  <KPI
                    icon={
                      <Warning
                        fontSize="large"
                        color={store.error > 0 ? "error" : "inherit"}
                      />
                    }
                    intlId="error"
                  >
                    {store.error}
                  </KPI>
                }
              >
                <ErrorRobotDataTable rows={store.errorRobotsDataTable} />
              </Dialog>

              <Dialog
                intlTitle="runStop"
                trigger={
                  <KPI
                    icon={
                      <Block
                        fontSize="large"
                        color={store.error > 0 ? "error" : "inherit"}
                      />
                    }
                    intlId="runStop"
                  >
                    {store.runStop}
                  </KPI>
                }
              >
                <RunStopRobotDataTable rows={store.runStopRobotsDataTable} />
              </Dialog>
            </div>
          </div>
        </div>
      </SimpleCard>
      <SimpleCard>
        <div>
          <KPIUnprocessed
            intlId="oldestUnprocessedRequest"
            oldestMessage={store.oldestMessage}
          />
        </div>
      </SimpleCard>
    </React.Fragment>
  );
});
