import { environment } from "environment";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { ITransportFilterParams } from "../../utils/transport-utils";

export class TransportDataTableProvider extends DataTableStore<
  any,
  ITransportFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();

  get endpointUrl(): string {
    return "ui/transports";
  }
  protected serviceApiUrl = environment.serviceApi;

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setHours(timeFrom.getHours() - 1);
    return timeFrom;
  }

  get defaultFilter() {
    const store = new SharedContextStore();
    return new Map<any, any>([
      //stationId is required
      ["stationId", store.appContext.currentStationId],
      //timeFrom is required
      ["timeFrom", this.defaultTimeFromValue.toISOString()],
    ]);
  }

  get filterDependencies(): Map<
    ITransportFilterParams,
    ITransportFilterParams[]
  > {
    return new Map<ITransportFilterParams, ITransportFilterParams[]>([
      ["sourceWorkplace", ["sourcePosition"]],
      ["targetWorkplace", ["targetPosition"]],
    ]);
  }
}
