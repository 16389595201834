import { DiagramMapStore } from "wes_shell_app/diagram-map-store";
import {
  IAPIImagesData,
  IAPIRobotsMapData,
  IWebSocketMapUpdateModel,
} from "models/server-models";
import { environment } from "environment";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class mapProvider extends DiagramMapStore<
  IAPIRobotsMapData,
  IAPIImagesData,
  IWebSocketMapUpdateModel
> {
  readonly sharedContextStore = new SharedContextStore();
  stationId = this.sharedContextStore.appContext.currentStationId;

  get endpointMapUrl() {
    return `ui/getRobotsMap/${this.stationId}`;
  }

  get endpointImageDataUrl() {
    return `ui/getImagesData/${this.stationId}`;
  }

  protected serviceApiUrl = environment.serviceApi;
}
