import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";
import TransportsChart from "../components/transports-chart";
import TransportsByRobotChart from "../components/transports-by-robot-chart";
import BuffersChart from "../components/buffers-chart";
import RobotsChart from "../components/robots-chart";
import { Box } from "@mui/material";

export const ChartsView = observer(() => {
  return (
    <BaseView cardBackgroundColor={false}>
      <TransportsChart />
      <TransportsByRobotChart />
      <Box sx={{ display: "flex", gap: "0.5rem" }}>
        <BuffersChart />
        <RobotsChart />
      </Box>
    </BaseView>
  );
});
