declare global {
  interface Window {
    _env_: any;
  }
}

const environmentVariables = window._env_;

export const environment = {
  structureServiceApi: environmentVariables.REACT_APP_STRUCTURE_SERVICE_API,
  eventlogServiceApi: environmentVariables.REACT_APP_EVENT_LOG_SERVICE_API,
  serviceApi: environmentVariables.REACT_APP_AMR_TRANSPORT_SERVICE_API,
  appWss: environmentVariables.REACT_APP_AMR_TRANSPORT_DASHBOARD_WSS,
  warehouseMapWss:
    environmentVariables.REACT_APP_AMR_TRANSPORT_WAREHOUSE_MAP_WSS,
};
