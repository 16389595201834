import { observer } from "mobx-react";
import { SimpleCard } from "wes_shell_app/simpleCard";
import TimeChart from "wes_shell_app/time-chart";
import { useChartsStore } from "../hooks/use-charts-store";

const RobotsChart = observer(() => {
  const store = useChartsStore().robots;

  return (
    <SimpleCard>
      <TimeChart
        data={store.items}
        store={store}
        intlId="robotsChart"
        type="line"
      />
    </SimpleCard>
  );
});

export default RobotsChart;
